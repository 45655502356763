<template>
  <div class="base-spinner">
    <div class="base-spinner__icon" />
  </div>
</template>

<script>
export default {
  name: "BaseSpinner",
  props: {
    text: { type: String },
  },

  data: () => ({
    stack: 0,
    isShown: false,
  }),
};
</script>

<style lang="scss">
.base-spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 20px;

  border-radius: 60px;
  background-color: #fff;

  box-shadow: 0 3px 26px rgba(0, 0, 0, 0.16);

  &__icon {
    position: relative;
    height: 50px;
    width: 50px;

    &:before {
      content: "";

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      border: 4px solid rgba(0, 0, 0, 0.1);
      border-radius: 100%;
    }

    &:after {
      content: "";

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      border: 4px solid transparent;
      border-top-color: #0032c8;

      animation: loader 0.6s linear;
      animation-iteration-count: infinite;
      border-radius: 100%;

      box-shadow: 0px 0px 0px 1px transparent;
    }
  }
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
