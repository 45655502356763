<template>
  <div id="app">
    <YandexMetrikaScript />
    <router-view />

    <div v-if="$store.state.isLoading" class="spinner">
      <base-spinner />
    </div>

    <base-background :styling="getBackgroundStyle" />
  </div>
</template>

<script>
import BaseSpinner from "@/components/BaseSpinner.vue";
import BaseBackground from "@/components/BaseBackground.vue";
import YandexMetrikaScript from "@/components/YandexMetrkca/YandexMetrikaScript.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ref, computed, onMounted, watch } from "vue";

export default {
  name: "App",

  components: {
    BaseSpinner,
    BaseBackground,
    YandexMetrikaScript,
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const getBackgroundStyle = computed(() => {
      let result;
      if (route.meta && route.meta.isCircleBackground === true) {
        switch (route.name) {
          case "main":
            result = [
              {
                position: { top: "-10%", left: "-20%" },
                style: "background-circle--pgreen",
              },
              {
                position: {
                  bottom: "-5%",
                  right: "-20%",
                  transform: "rotate(-100deg)",
                },
                style: "background-circle--pyellow",
              },
            ];
            break;

          default:
            result = [
              {
                position: { top: "-5%", left: "-20%" },
                style: "background-circle--bcyan",
              },
              {
                position: { top: "10%", left: "-20%" },
                style: "background-circle--bcyan",
              },
              {
                position: {
                  top: "25%",
                  right: "-20%",
                  transform: "rotate(-100deg)",
                },
                style: "background-circle--ygreen",
              },
              {
                position: {
                  top: "30%",
                  right: "-20%",
                  transform: "rotate(-100deg)",
                },
                style: "background-circle--ygreen",
              },
              {
                position: { top: "52%", left: "-20%" },
                style: "background-circle--bcyan",
              },
              {
                position: {
                  bottom: "-10%",
                  right: "-20%",
                  transform: "rotate(-100deg)",
                },
                style: "background-circle--ygreen",
              },
            ];
        }
      } else {
        result = [{ display: "none" }];
      }

      return result;
    });

    const setVuexData = () => {
      Object.entries(route.query).forEach(([path, value]) => {
        store.commit("setData", { path, value });
      });
    };

    const fetchUserData = async () => {
      const { dispatch, commit } = store;

      commit("setLoader", true);
      const response = await dispatch("fetchUserData");
      commit("setLoader", false);

      if (response.status) {
        Object.entries(response.data).forEach(([path, value]) => {
          commit("setData", { path, value });
        });
      }
    };

    const init = () => {
      setVuexData();
      fetchUserData();
    };

    onMounted(() => {
      init();
    });

    watch(
      route,
      (newRoute, oldRoute) => {
        if (Object.keys(newRoute.query).length !== 0) {
          console.log("Route query changed:", newRoute.query);
          setVuexData();
        }
      },
      { immediate: true },
    );

    return {
      getBackgroundStyle,
    };
  },
};
</script>

<style lang="scss">
.spinner {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  background-color: rgba(#fff, 0.8);

  > .base-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
