<template>
  <div class="point-info__main">
    <div v-if="!$store.state.isLoading" class="point-info__inner">
      <div class="point-info__content">
        <h2 class="point-info__title">
          {{ point.waypoint_title }}
        </h2>
        <div class="point-info__image">
          <img :src="`${$store.state.SERVER}/storage/${point.waypoint_image}`" alt="" />
        </div>
      </div>
      <div class="point-info__description">
        <div class="point-info__description-title">
          <div v-if="point.waypoint_audio" class="point-info__audio">
            <audio-guide :audio-message="`${point.waypoint_audio}`" />
          </div>
        </div>
        <div class="point-info__info" v-html="point.waypoint_description" />
      </div>
    </div>
  </div>
</template>

<script>
import AudioGuide from "./AudioGuide.vue";

export default {
  name: "PointInfo",

  components: { AudioGuide },

  props: {
    point: { type: Object, default: () => {} },
  },
};
</script>

<style lang="scss" scoped>
.point-info {
  &__inner {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__title {
    font-size: 17px;
    font-weight: 700;
    line-height: 17px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-around;
  }

  &__image {
    width: 100%;
    height: 163px;
    border-radius: 20px;
    overflow: hidden;
  }

  &__description-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 18px;
    padding-bottom: 15px;
    font-weight: 700;
    line-height: 17px;
  }

  &__info {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    overflow: auto;
    min-height: calc(100vh - 560px);
    // height: calc(100vh - 560px);
    border-radius: 8px;
    background-color: #f7f7f7;
    padding: 7px 9px;
    margin-bottom: 88px;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__description {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 20px;
      background-color: rgb(255, 255, 255);
      bottom: -10px;
      left: 0;
      filter: blur(4px);
      opacity: 0.5;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
