<template>
  <div class="result-status">
    <div
      class="result-status__inner"
      :class="[getStatus ? 'result-status__inner--success' : 'result-status__inner--error']"
    >
      <div class="result-status__image">
        <img
          class="result-status__picture"
          :src="require(`../assets/images/${getStatus ? 'success_result' : 'error_result'}.svg`)"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultStatus",

  props: {
    status: { type: Number },
  },

  computed: {
    getStatus() {
      return ![10, 404, 405, 406, 500].includes(this.status);
    },
  },
};
</script>

<style lang="scss" scoped>
.result-status {
  width: 184px;
  height: 184px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(243, 243, 243, 0.5);

  &__inner {
    position: absolute;
    width: 159px;
    background: #ffffff;
    height: 159px;
    border-radius: 50%;

    &--error {
      box-shadow: 0px 0px 94px rgba(224, 45, 45, 0.5);
    }

    &--success {
      box-shadow: 0px 0px 94px rgba(86, 218, 57, 0.7);
    }
  }

  &__image {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
  }

  &__picture {
    margin: 0 auto;
  }
}
</style>
