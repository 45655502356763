<template>
  <p class="info__stock">
    {{ stock ? "В наличии" : "Нет в наличии" }}
  </p>
</template>

<script>
export default {
  props: {
    stock: Boolean,
  },
};
</script>

<style lang="scss">
@import "../../../styles/_variables.scss";
.info {
  &__stock {
    color: $color-positive;
  }
}
</style>
