import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/index.scss";

// Vue.config.productionTip = false;
//
// new Vue({
//   router,
//   store,
//   render: (h) => h(App),
// }).$mount("#app");
const app = createApp(App);

app.use(router);
app.use(store);

app.mount("#app");
