<template>
  <div class="wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Wrapper",
};
</script>

<style lang="scss">
.wrapper {
  border-radius: 16px 16px 0 0;
  padding: 22px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  background: #fff;
  z-index: 2;
}
</style>
