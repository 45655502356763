<template>
  <div class="base-background">
    <background-circle
      v-for="(item, index) in styling"
      :key="`${index}_circle`"
      :style="item.position"
      :classList="item.style"
    />
  </div>
</template>

<script>
import BackgroundCircle from "@/components/BackgroundCircle.vue";

export default {
  name: "BaseBackground",

  components: { BackgroundCircle },

  props: {
    styling: { type: Array, default: () => {} },
  },
};
</script>

<style lang="scss" scoped></style>
