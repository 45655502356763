<template>
  <div class="route-description">
    <div class="route-description__inner">
      <div class="route-description__header">
        <h2 class="route-description__title">{{ routeData.route_title }}</h2>
      </div>

      <div class="route-description__image">
        <img :src="`${$store.state.SERVER}/storage/${routeData.route_image}`" alt="" />
      </div>

      <div class="route-description__block">
        <div class="route-description__metrics">
          <p class="route-description__metric">Точек: {{ routeData.waypoints?.length }}</p>
          <p class="route-description__metric">Минут: {{ routeData.route_time }}</p>
          <p class="route-description__metric">{{ routeData.route_distance }} км</p>
          <div class="route-description__points">
            <p class="route-description__points-content">
              +{{ routeData.route_type?.route_type_point ?? "" }}
            </p>
          </div>
        </div>
      </div>

      <div class="route-description__info">
        <div class="route-description__subtitle">
          <div v-if="routeData.route_audio" class="route-description__audio">
            <audio-guide :audio-message="`${routeData.route_audio}`" />
          </div>
        </div>
      </div>
      <div class="route-description__content" v-html="routeData.route_description ?? ''" />
    </div>
  </div>
</template>

<script>
import AudioGuide from "@/components/AudioGuide.vue";

export default {
  name: "RouteDescription",

  components: {
    AudioGuide,
  },

  props: {
    routeData: { type: Object },
  },
};
</script>

<style lang="scss" scoped>
.route-description {
  height: 100%;
  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 163px;
    border-radius: 20px;
    margin-bottom: 23px;
    overflow: hidden;
  }

  &__metric {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  &__subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 17px;
    padding: 10px 0;
  }

  &__place {
    display: flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
  }

  &__info {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 20px;
      background-color: rgb(255, 255, 255);
      bottom: -10px;
      left: 0;
      filter: blur(4px);
      opacity: 0.5;
    }
  }

  &__city {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  &__block {
  }

  &__content {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    overflow: auto;
    min-height: calc(100vh - 600px);
    // height: calc(100vh - 600px);
    border-radius: 8px;
    background-color: #f7f7f7;
    padding: 7px 9px;
    margin-bottom: 88px;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 17px;
    text-align: left;

    margin-bottom: 30px;
  }

  &__points {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #f4be00;
    border-radius: 20px;
    padding: 3px 6px;
    display: flex;
    gap: 7px;
    justify-content: center;
    align-items: center;
  }

  &__points-content {
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  &__metrics {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #f7f7f7;
    padding: 9px 11px;
    border-radius: 8px;
  }
}
</style>
