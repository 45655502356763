<template>
  <div class="route-info-page">
    <div class="route-info-page__inner">
      <div class="route-info-page__header">
        <button
          @click="$router.push({ name: 'paths' })"
          class="route-info-page__action--absolute button--default"
        >
          &lt; Назад
        </button>
      </div>

      <wrapper class="route-info-page__content">
        <route-description :route-data="route" />
      </wrapper>

      <div v-if="!route.is_finished" class="route-info-page__actions">
        <button
          @click="$router.push({ name: 'routePoints' })"
          class="route-info-page__action button button--positive button--pseudo"
        >
          Начать
        </button>
      </div>

      <div v-else class="route-info-page__actions">
        <button
          class="route-info-page__action button button--outline"
          @click="$router.push({ name: 'main' })"
        >
          На главную
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Wrapper from "@/components/Wrapper.vue";
import RouteDescription from "@/components/RouteDescription.vue";

export default {
  name: "RouteInfoPage",

  components: { Wrapper, RouteDescription },

  data: () => ({
    route: {},
  }),

  created() {
    this.init();
  },

  methods: {
    async fetchData() {
      const { commit, dispatch } = this.$store;
      try {
        commit("setLoader", true);
        const response = await dispatch("fetchPath", {
          route_id: Number(this.$route.params.id),
        });
        commit("setLoader", false);

        this.route = {
          ...response.data.route,
          waypoints: response.data.waypoints,
        };
      } catch (e) {
        commit("setLoader", false);
        console.warn("Error:", e);
      }
    },

    init() {
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.route-info-page {
  position: relative;
  height: 100vh;

  &__actions--absolute {
  }

  &__action--absolute {
    margin: 0 auto;
    width: 159px !important;
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 139px;
  }

  &__header {
    padding: 100px 17px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 139px;
    background-color: #fff;
    z-index: 10;
  }

  &__actions {
    position: fixed;
    bottom: 56px;
    left: 0;
    width: 100%;
    padding: 0 35px;
    z-index: 10;

    align-items: center;
    display: flex;
    gap: 10px;
  }

  &__action {
    width: 159px !important;
    margin: 0 auto;
  }
}
</style>
