<template>
  <div class="background-circle" :class="classList" />
</template>

<script>
export default {
  name: "BackgroundCircle",

  props: {
    classList: { type: String, default: "" },
  },
};
</script>

<style lang="scss" scoped>
.background-circle {
  position: fixed;
  z-index: -9999;

  width: 301px;
  height: 259px;
  border-radius: 62% 38% 100% 0% / 100% 44% 56% 0%;
  filter: blur(40px);

  &--pgreen {
    background: linear-gradient(
      220deg,
      rgba(228, 109, 202, 0.6527145770417542) 10%,
      rgba(0, 200, 164, 0.6499134565935749) 100%
    );
  }

  &--pyellow {
    background: linear-gradient(
      220deg,
      rgba(255, 231, 108, 0.6456074861585259) 10%,
      rgba(232, 0, 139, 0.6484086066067052) 100%
    );
  }

  &--bcyan {
    background: linear-gradient(
      220deg,
      rgba(0, 50, 200, 0.6527145770417542) 10%,
      rgba(0, 200, 164, 0.6471123361453957) 100%
    );
  }

  &--ygreen {
    background: linear-gradient(
      220deg,
      rgba(255, 231, 108, 0.6499134565935749) 10%,
      rgba(0, 232, 79, 0.6499134565935749) 100%
    );
  }
}
</style>
