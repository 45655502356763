<template>
  <div class="award-page">
    <div class="award-page__inner">
      <wrapper>
        <div class="award-page__product product">
          <div class="product__info info">
            <award-title :awardTitle="product.product_name"></award-title>
            <award-image-wrapper
              class="award-page__image-wrapper"
              :titleImgUrl="photos[0]"
              :isNoImages="isNoImages"
            >
              <template v-slot:preload>
                <pre-loader :isLoading="isPhotoLoading"></pre-loader>
              </template>
            </award-image-wrapper>
            <div class="award-page__price-stock">
              <p class="award-page__price">
                {{ product.price }} {{ getNoun(product.price, declScores) }}
              </p>
              <award-stock :stock="product.product_stocks"></award-stock>
            </div>
            <award-details class="details__photo photos" :detail="{ title: '' }">
              <template #award-content>
                <div v-for="(photo, idx) in photos" :key="idx" class="photos__item">
                  <modal-photo :photo="photo"></modal-photo>
                </div>
                <div class="award-page__image_out" v-if="isPhotoLoading">
                  <pre-loader :isLoading="isPhotoLoading"></pre-loader>
                </div>
              </template>
            </award-details>
            <award-details
              class="award-page__description"
              :detail="{
                title: '',
                subtitle: product.product_description,
              }"
            />
          </div>
          <modal-warn
            :message="errorMessage"
            dismissButtonValue="Закрыть"
            :isModalVisible="isErrorVisible"
            @modal-dismissed="dismissError"
          ></modal-warn>
          <modal-success
            dismissButtonValue="Назад"
            :isModalVisible="isSuccessPurchase"
            @modal-dismissed="dismissSuccessPurchase"
          >
            <p class="award-page_success-modal_text">Награда почти у вас!</p>
            <p class="award-page_success-modal_text">Ожидайте email уведомление</p>
          </modal-success>
        </div>
      </wrapper>

      <div
        class="award-page__actions"
        :class="{
          'award-page__actions_purchase-process': isSuccessPurchase || isErrorVisible,
        }"
      >
        <button
          @click="$router.push({ name: 'store' })"
          class="button button--outline award-page__action--small"
        >
          Назад
        </button>

        <button @click="purchaseProduct" class="button button--orange-2 award-page__action">
          Получить за
          {{ `${product.price} ${getNoun(product.price, declScores)}` }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AwardDetails from "@/components/award/award-details/AwardDetails.vue";
import Wrapper from "@/components/Wrapper.vue";
import ModalWarn from "@/components/shared/components/modals/ModalWarn.vue";
import ModalPhoto from "@/components/shared/components/modals/ModalPhoto.vue";
import ModalSuccess from "@/components/shared/components/modals/ModalSuccess.vue";
import PreLoader from "@/components/shared/components/PreLoader.vue";
import AwardImageWrapper from "@/components/award/award-image-wrapper/AwardImageWrapper.vue";
import AwardTitle from "@/components/award/award-title/AwardTitle.vue";
import AwardStock from "@/components/award/award-stock/AwardStock.vue";
import { formatNoun } from "@/services/formatNoun.ts";

export default {
  name: "AwardPage",

  components: {
    Wrapper,
    AwardDetails,
    AwardImageWrapper,
    AwardTitle,
    AwardStock,
    ModalWarn,
    ModalPhoto,
    ModalSuccess,
    PreLoader,
  },

  data: () => ({
    product: {},

    photos: [],
    isPhotoLoading: false,

    sizeRange: [],
    declScores: ["балл", "балла", "баллов"],
    isErrorVisible: false,
    errorMessage: "",
    isSuccessPurchase: false,
    susccessMessage: "Награда почти у вас! Ожидайте email уведомление",
    isNoImages: false,
  }),

  methods: {
    init() {
      this.fetchAwardItem();
    },

    async purchaseProduct() {
      const { commit, dispatch } = this.$store;

      try {
        let response;
        commit("setLoader", true);
        response = await dispatch("purchaseProduct", {
          product_id: this.product.id,
        });
        commit("setLoader", false);
        if (response.status) {
          this.isSuccessPurchase = true;
        }

        const handlingErrors = [400, 401, 402, 403, 404];
        if (handlingErrors.find((error) => error === response.data.status)) {
          this.isErrorVisible = true;
          this.errorMessage =
            response.data.status === 404 ? response.data.message : response.data.messages;
        } else if (!response.status) {
          this.errorMessage = "Что-то пошло не так";
          this.isErrorVisible = true;
        }
      } catch (e) {
        commit("setLoader", false);
        console.warn("Error:", e);
      }
    },

    sortData(productData) {
      Object.keys(productData).map((key) => {
        if (key == "sizes") {
          this.sizeRange = productData[key];
        } else {
          this.product = { ...this.product, ...productData };
        }
      });
    },

    async fetchAwardItem() {
      try {
        const { commit, dispatch } = this.$store;

        commit("setLoader", true);
        const response = await dispatch("fetchAwardItem", {
          award_id: Number(this.$route.params.id),
        });
        commit("setLoader", false);
        this.isPhotoLoading = false;

        if (response.status) {
          this.sortData(response.data[0]);
          this.fetchImages();
        } else {
          console.log("Data: ", response.data);
        }
      } catch (e) {
        console.warn("Error: ", e);
      }
    },

    async fetchImages() {
      try {
        const { dispatch } = this.$store;
        if (this.product.images.length) {
          this.isPhotoLoading = true;
          this.product.images.map(async ({ image }) => {
            try {
              let photo = await dispatch("fetchImage", {
                image: image,
              });
              this.photos = [...this.photos, photo];
              this.isPhotoLoading = false;
              this.getIsNoImages();
            } catch (e) {
              this.handleFetchImagesError();
            }
          });
        } else {
          this.handleFetchImagesError();
        }
      } catch (e) {
        console.warn("Error: ", e);
        this.handleFetchImagesError();
      }
    },

    handleFetchImagesError() {
      this.isPhotoLoading = false;
      this.getIsNoImages();
    },

    getIsNoImages() {
      if (this?.photos[0]) {
        this.isPhotoLoading = false;
        this.isNoImages = false;
      } else {
        this.isNoImages = true;
      }
    },

    getNoun(number, [one, two, five]) {
      return formatNoun(number, [one, two, five]);
    },

    dismissError() {
      this.isErrorVisible = false;
      this.errorMessage = "";
    },

    dismissSuccessPurchase() {
      this.isSuccessPurchase = false;
      this.$nextTick(() => {
        this.$router.back();
      });
    },
  },

  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
.award-page {
  height: 100vh;
  background-color: $color-white;
  &__product {
    padding: 0px 0px 100px;
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 25%;
    @media screen and (min-width: 768px) {
      padding-top: 120px;
    }
  }

  &__actions {
    position: fixed;
    bottom: 56px;
    left: 0;
    width: 100%;
    padding: 0 35px;
    z-index: 10;

    align-items: center;
    display: flex;
    gap: 10px;

    &_purchase-process {
      position: static;
      width: 100%;
      z-index: 1;
      padding-bottom: 20px;
      background-color: $color-white;
    }
  }

  &__action {
    margin: 0 auto;
    font-size: 14px;
    font-weight: 700 !important;
    line-height: 19px !important;
    box-shadow: 0px 4px 14px rgb(84 184 216 / 25%);
  }

  &__action--small {
    font-size: 14px;
    font-weight: 700 !important;
    line-height: 19px !important;
    width: 200px !important;
  }

  &__image-wrapper {
    margin-top: 20px;
    align-self: center;
  }

  &__price-stock {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
  }

  &__price {
    color: $color-orange-2;
  }

  &__description {
    margin-top: 20px;
  }

  .info {
    display: flex;
    flex-direction: column;

    .sizes__item {
      width: 28px;
      text-align: center;
      line-height: 24.2px;
      font-size: 20px;
      font-weight: 400;

      &--active {
        font-weight: bold;
        color: #ff8a00;
      }

      &--disabled {
        color: #9d9d9d;
        pointer-events: none;
      }
    }

    .photos__item > * {
      border-radius: 20px;
      height: 83px;
      width: 67px;
    }
  }

  &__title {
    font-weight: bold;
    text-align: center;
    font-size: 32px;
  }

  &__image {
    &_out {
      display: flex;
      width: 100%;
      height: 100%;
      min-height: 100px;
      padding: 0;
      margin: auto;
      justify-content: start;
      align-items: start;
    }
  }

  &_success-modal {
    &_text {
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      color: #56da39;
      margin-bottom: 20px;
      font-weight: 700;
    }
  }
}
</style>
