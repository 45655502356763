<template>
  <div class="paths-page">
    <div class="paths-page__inner">
      <div class="paths-page__back-button-section">
        <button @click="goBack" class="paths-page__action button--default">&lt; Назад</button>
      </div>
      <div class="paths-page__header">
        <h2 class="paths-page__title">Выберите маршрут</h2>
      </div>
      <div class="paths-page__main">
        <div class="paths-page__paths">
          <route-item v-for="route in paths" :key="route.id" @go-route="goToRoute" :route="route" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteItem from "@/components/RouteItem.vue";

export default {
  name: "PathsPage",

  components: {
    RouteItem,
  },

  data: () => ({
    paths: [],
  }),

  created() {
    this.init();
  },

  methods: {
    goBack() {
      this.$router.push({ name: "main" });
    },

    goToRoute(routeId) {
      this.$router.push({ path: `/path/${routeId}` });
    },

    init() {
      this.fetchData();
    },

    async fetchData() {
      const { dispatch, commit } = this.$store;
      try {
        commit("setLoader", true);
        const response = await dispatch("fetchPaths");
        commit("setLoader", false);

        this.paths = response.data;
      } catch (e) {
        commit("setLoader", false);
        console.warn("Error:", e);
      }
    },
  },
};
</script>

<style lang="scss">
.paths-page {
  min-height: 100vh;

  &__header {
    margin-top: 99px;

    font-weight: 700;
    font-size: 17px;
    line-height: 17px;
    color: #343434;
  }

  &__inner {
    padding: 40px 17px 40px;
  }

  &__actions {
  }

  &__action {
    margin: 0 auto;
    width: 159px !important;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    padding: 10px 10px 23px;
  }

  &__back-button-section {
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    padding: 100px 17px 0;
    width: 100%;
    max-height: 139px;
    z-index: 10;
  }
}
</style>
