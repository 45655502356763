<template>
  <div class="point-page">
    <div class="point-page__inner">
      <div class="point-page__header">
        <button @click="$router.back()" class="paths-page__action--absolute button--default">
          &lt; Назад
        </button>
      </div>

      <wrapper class="point-page__content">
        <router-view v-bind="{ point }" />
      </wrapper>

      <div v-if="point.is_virtual" class="point-page__actions">
        <button
          v-if="$route.name === 'point'"
          @click="completeVirtualPoint()"
          :disabled="point.pivot.is_finished"
          class="button button--positive button--pseudo point-page__action--small"
        >
          <span>Я здесь</span>
        </button>
      </div>
      <div v-else class="point-page__actions">
        <button
          v-if="$route.name === 'point'"
          @click="$router.replace({ path: `${point.pivot.waypoint_id}/scan` })"
          :disabled="point.pivot?.is_finished"
          class="point-page__action--small button button--positive button--pseudo"
        >
          Сканировать
        </button>
      </div>
    </div>
    <modal-warn
      :message="error"
      dismissButtonValue="Закрыть"
      :isModalVisible="error"
      @modal-dismissed="dismissError"
    ></modal-warn>
    <modal-success
      dismissButtonValue="Назад"
      :isModalVisible="isSuccess"
      @modal-dismissed="dismissSuccessModal"
    >
      <p class="success-modal__text">Вы успешно прошли точку!</p>
      <div class="success-modal__image">
        <img :src="require('../assets/images/success_result.svg')" alt="" />
      </div>
    </modal-success>
  </div>
</template>

<script>
import Wrapper from "@/components/Wrapper.vue";
import ModalWarn from "@/components/shared/components/modals/ModalWarn.vue";
import ModalSuccess from "@/components/shared/components/modals/ModalSuccess.vue";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "PointPage",

  data: () => ({
    point: {},
    error: "",
    isSuccess: false,
  }),

  components: {
    Wrapper,
    ModalWarn,
    ModalSuccess,
  },

  methods: {
    init() {
      this.fetchData();
    },

    ...mapMutations(["setLoader"]),
    ...mapActions(["getCurrentGeolocation", "postScanVirtualPoint"]),

    async fetchData() {
      const { commit, dispatch } = this.$store;
      try {
        commit("setLoader", true);
        const response = await dispatch("fetchPoint", {
          waypoint_id: Number(this.$route.params.id),
          route_id: this.getRouteID,
        });
        commit("setLoader", false);

        this.point = response.data.data;
      } catch (e) {
        commit("setLoader", false);
        console.warn("Error:", e);
      }
    },

    async completeVirtualPoint() {
      this.setLoader(true);

      try {
        const user_position = await this.getCurrentGeolocation();

        const response = await this.postScanVirtualPoint({
          route_id: this.getRouteID,
          waypoint_id: this.point.id,
          user_position,
        });

        if (!response.success) {
          throw new Error(response.message);
        }

        this.isSuccess = true;
      } catch (e) {
        this.error = e.message;
      } finally {
        this.setLoader(false);
      }
    },

    dismissError() {
      this.error = "";
    },

    dismissSuccessModal() {
      this.isSuccess = false;
      this.$nextTick(() => {
        this.$router.back();
      });
    },
  },

  computed: {
    getRouteID() {
      return Number(this.$route.path.match(/[0-9]{1,2}/g)[0]);
    },

    getScanResult() {
      return this.$store.getters.scan;
    },
  },

  created() {
    this.init();
  },
};
</script>

<style lang="scss">
.point-page {
  position: relative;
  height: 100vh;

  &__action--absolute {
    margin: 0 auto;
    width: 159px !important;
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 139px;
  }

  &__header {
    padding: 100px 17px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 139px;
    background-color: #fff;
    z-index: 10;
  }

  &__actions {
    position: fixed;
    bottom: 56px;
    left: 0;
    width: 100%;
    padding: 0 35px;
    z-index: 10;

    align-items: center;
    justify-content: center;
    display: flex;
    gap: 10px;
  }

  &__action {
    width: 123px !important;
    margin: 0 auto;
  }

  &__action--small {
    width: 168px !important;
    padding: 10px 5px 10px 15px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 19px !important;

    span {
      color: inherit;
      width: 100%;
      text-align: center;
    }
  }

  .success-modal {
    &__text {
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 1rem;
    }

    &__image {
      margin-bottom: 1rem;
      border-radius: 50%;

      img {
        position: relative;
        scale: 0.75;
      }
    }
  }
}
</style>
