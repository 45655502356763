<template>
  <div class="store-page">
    <div class="store-page__inner">
      <div class="store-page__header">
        <h2 class="store-page__title">Выберите поощрение</h2>
      </div>
      <div class="store-page__main">
        <div class="store-page__awards">
          <award
            imgWrapperHeight="120"
            class="store-page__award"
            v-for="product in products"
            :key="product.id"
            :award="product"
            @click="goToAward(product.id)"
          >
            <template #footer>
              <p class="award__price-content">
                Получить за {{ product.price }}
                {{ getNoun(product.price, declScores) }}
              </p>
            </template>
          </award>
        </div>
      </div>

      <div class="store-page__actions">
        <button @click="goBack" class="store-page__action button button--outline">Назад</button>
      </div>
    </div>
  </div>
</template>

<script>
import Award from "@/components/award/Award.vue";
import { formatNoun } from "@/services/formatNoun.ts";

export default {
  name: "StorePage",

  components: { Award },

  data: () => ({
    products: [],
    declScores: ["балл", "балла", "баллов"],
  }),

  methods: {
    init() {
      this.fetchAwards();
    },

    goBack() {
      this.$router.push({ name: "main" });
    },

    async fetchAwards() {
      const { commit, dispatch } = this.$store;

      try {
        commit("setLoader", true);
        const response = await dispatch("fetchAwards");
        commit("setLoader", false);

        if (response.status) {
          this.products = response.data;
        } else {
          console.log(response);
        }
      } catch (e) {
        commit("setLoader", false);
        console.warn("Error: ", e);
      }
    },

    goToAward(id) {
      this.$router.push({ path: `/store/${id}` });
    },

    getNoun(number, [one, two, five]) {
      return formatNoun(number, [one, two, five]);
    },
  },

  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
.store-page {
  min-height: 100vh;
  background: $color-white;

  &__inner {
    padding: 17px 17px 100px 17px;
  }

  &__header {
    margin-top: 100px; // fix-header
  }

  &__title {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    padding: 8px 0 20px;
    font-weight: 700;
  }

  &__actions {
    position: fixed;
    bottom: 56px;
    left: 0;
    width: 100%;
    padding: 0 22px;
    z-index: 10;
    margin: 0 auto;
  }

  &__action {
    margin: 0 auto;
    width: 159px !important;
  }

  &__award {
    background-color: $color-white;
    height: auto;
    min-height: 184px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 12px 16px;
    filter: drop-shadow(0 4px 14px rgba($color-blue-shadow, 0.1));
  }
}
.award__price-content {
  color: #ff8a00;
  font-weight: bold;
  font-size: 21px;
  line-height: 25.41px;
}
</style>
