<template>
  <div
    @click="playAudio"
    class="audio-guide"
    :class="[audioPlay ? 'audio-guide--play' : 'audio-guide--pause']"
  >
    <div class="audio-guide__inner">
      <audio
        ref="audioRef"
        :src="`${$store.state.SERVER}/storage/${audioMessage}`"
        preload="auto"
      />

      <div class="audio-guide__block">
        <span class="audio-guide__text">
          {{ getAudioTimer }}
        </span>

        <div class="audio-guide__content">
          <p v-if="!audioPlay" class="audio-guide__text">Аудио-гид</p>
          <div v-else class="audio-guide__wave">
            <svg id="svg-wave" height="14" width="60" fill="none">
              <path
                id="svg-bar"
                v-for="(item, idx) in barHeight"
                :key="`M${idx * 3 + 1} 1V${item}`"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                :transform="`translate(0, ${(14 - item) / 2})`"
                :d="`M${idx * 3 + 1} 1V${item}`"
              />
            </svg>
          </div>
        </div>

        <div class="audio-guide__button" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AudioGuide",

  props: {
    audioMessage: { type: String },
  },

  data() {
    return {
      audioPlay: false,
      duration: 0,

      barHeight: Array.from({ length: 20 }, () => Math.floor(Math.random() * 12) + 2),
    };
  },

  computed: {
    getAudioTimer() {
      return this.convertTime(this.duration);
    },
  },

  methods: {
    playAudio() {
      if (!this.audioPlay) {
        this.$refs.audioRef.play();
      } else {
        this.$refs.audioRef.pause();
      }
    },

    convertTime(time) {
      const seconds = parseInt(time % 60);
      const minutes = parseInt((time / 60) % 60);

      return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
    },
  },

  mounted() {
    this.$refs.audioRef.volume = "0.5";
    this.$refs.audioRef.addEventListener("playing", () => (this.audioPlay = true), false);
    this.$refs.audioRef.addEventListener("ended", () => (this.audioPlay = false), false);
    this.$refs.audioRef.addEventListener("pause", () => (this.audioPlay = false), false);

    this.$refs.audioRef.addEventListener("loadedmetadata", (event) => {
      this.duration = event.target.duration;
    });
    this.$refs.audioRef.addEventListener(
      "timeupdate",
      ({ target }) => (this.duration = target.duration - target.currentTime),
      false,
    );
  },
};
</script>

<style lang="scss" scoped>
.audio-guide {
  $self: &;

  width: 155px;
  box-shadow: 0px 4px 14px rgba(84, 184, 216, 0.25);
  border-radius: 20px;

  &__inner {
    padding: 7px 10px;
  }

  &__wave {
    display: flex;
    align-items: center;
    gap: 1px;
  }

  &__wave-item {
    background: #fff;
    height: 10px;
    width: 2px;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__button {
    width: 21px;
    height: 21px;
    border-radius: 20px;
  }

  &__text {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
  }

  &--play {
    background: #56da39;

    #{$self}__text {
      color: #fff !important;
    }

    #{$self}__button {
      background-color: #fff;
      background-image: url("../assets/images/pause.svg");
      background-position: 50% center;
      background-repeat: no-repeat;
      background-size: 40%;
    }
  }

  &--pause {
    background: #fff;

    #{$self}__text {
      color: #56da39 !important;
    }

    #{$self}__button {
      background-color: #56da39;
      background-image: url("../assets/images/play.svg");
      background-position: 55% center;
      background-repeat: no-repeat;
      background-size: 46%;
    }
  }
}
</style>
