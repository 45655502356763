<template>
  <div class="award__image-wrapper">
    <div v-if="titleImgUrl">
      <img
        class="award__image"
        :style="{ height: imgWrapperHeight + 'px' }"
        :src="titleImgUrl"
        alt="award image"
      />
    </div>
    <slot class="award__image-preloader" name="preload"></slot>
    <p class="award__image_out" v-if="isNoImages">Нет изображений</p>
  </div>
</template>

<script>
export default {
  props: {
    titleImgUrl: String,
    isNoImages: Boolean,
    imgWrapperHeight: String,
  },
};
</script>

<style lang="scss" scoped>
.award {
  &__image {
    border-radius: 10px;
    object-fit: contain;
    width: 100%;
    max-width: 720px;
    max-height: 165px;

    &_out {
      text-align: center;
    }
  }

  &__image-wrapper {
    width: 100%;
    object-fit: contain;
    display: flex;
    height: 100%;
    min-height: 120px;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}
</style>
