<template>
  <div class="modal" :class="{ modal_active: isModalActive }">
    <img
      :src="photo"
      alt="photo"
      class="modal__photo"
      :class="{ modal__photo_active: isModalActive }"
      @click="handleClickPhoto"
    />
  </div>
</template>

<script>
import image from "../../../../assets/images/iwalk-tech-issue-photo.webp";

export default {
  name: "ModalTechIssue",
  props: ["active"],
  data() {
    return {
      isModalActive: this.active || false,
      photo: image,
    };
  },
  watch: {
    photo: function (value) {
      return value;
    },
    isModalActive: function (value) {
      return value;
    },
  },
  methods: {
    handleClickPhoto() {
      this.isModalActive = !this.isModalActive;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/variables";

.modal {
  display: none;

  &_active {
    display: block;
    overflow: scroll;
    overscroll-behavior: none;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 20;
    height: 100dvh;
    width: 100dvw;

    &::before {
      display: block;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-color: $color-black;
      opacity: 0.8;
    }
  }

  &__photo {
    &_active {
      display: block;
      position: relative;
      object-fit: contain;
      padding: 1rem;
    }
  }
}
</style>
