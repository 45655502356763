<template>
  <div class="participant-page">
    <div class="participant-page__inner">
      <div class="participant-page__header">
        <p class="participant-page__title">Ваше место в рейтинге</p>
        <div class="participant-page__stats user-stats">
          <span class="user-stats__place">
            {{ currentUser.client_position }}
          </span>
          <div class="user-stats__score">
            <p class="user-stats__name">{{ currentUser.client_sub }}</p>
            <p class="user-stats__points">
              {{ currentUser.points }}
              <img
                :style="{ objectFit: 'none' }"
                :src="require('../assets/images/coin_icon.svg')"
                alt=""
              />
            </p>
          </div>
        </div>
      </div>

      <wrapper>
        <div class="paticipant-page__rating user-rating">
          <div class="user-rating__header">
            <p class="user-rating__title">Рейтинг участников</p>
          </div>
          <div class="user-rating__content">
            <ul class="user-rating__list">
              <li
                v-for="(rating, idx) in ratings"
                :key="`${rating.client_sub}_${idx}`"
                class="user-rating__item"
              >
                <div class="user-rating__position" :class="`${getClass(rating.client_position)}`">
                  {{ rating.client_position }}
                </div>
                <div class="user-rating__name">{{ rating.client_sub }}</div>
                <div class="user-rating__points">
                  {{ rating.points }}
                  <img
                    width="20"
                    height="20"
                    :src="require('../assets/images/coin_icon.svg')"
                    alt=""
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </wrapper>

      <div class="participant-page__actions">
        <button @click="$router.back()" class="participant-page__action button button--outline">
          Назад
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Wrapper from "@/components/Wrapper.vue";

export default {
  name: "ParticipantPage",

  components: { Wrapper },

  data: () => ({
    currentUser: {},
    ratings: [],
  }),

  computed: {
    getClientName() {
      const [name, surname] = this.$store.state.client_name.split(" ");
      return `${name} ${surname.charAt(0)}.`;
    },
  },

  methods: {
    init() {
      this.fetchRating();
    },

    getClass(position) {
      return [
        "user-rating__position--first",
        "user-rating__position--second",
        "user-rating__position--third",
      ][--position];
    },

    async fetchRating() {
      const { commit, dispatch } = this.$store;
      try {
        commit("setLoader", true);
        const response = await dispatch("fetchRating");
        commit("setLoader", false);

        if (response.status) {
          this.currentUser = response.data["current_user"];
          this.ratings = response.data["clients_rating"];
        } else {
          console.log("Rating: ", response.data);
        }
      } catch (e) {
        commit("setLoader", false);
        console.warn("Error:", e);
      }
    },
  },

  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.participant-page {
  height: 100vh;

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding: 70px 28px 15px 28px;
    margin-top: 100px; //fix-header
  }

  &__stats {
    padding-top: 5px;
  }

  .user-rating {
    height: 100%;
    overflow: hidden;
    &__title {
      font-size: 22px;
      font-weight: 700;
      line-height: 27px;
    }

    &__content {
      padding: 10px 0 0;
    }

    &__coin {
      width: 18px;
      height: 18px;
      position: relative;
      bottom: 2px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      line-height: 18px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    &__name {
      flex: 1 1 auto;
    }

    &__points {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    &__list {
      height: calc(100vh - 464px);
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    li:nth-child(-n + 3) {
      font-size: 23px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 14px;
      border: none;

      .user-rating__position {
        font-size: 23px;
        font-weight: 700;
        line-height: 28px;
      }

      .user-rating__name {
        padding-left: 14px;
      }
    }

    &__position {
      height: 34px;
      width: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      margin-right: 0.5rem;

      &--first {
        background: #f3a94b;
        border-radius: 50%;
        color: #fff;
      }

      &--second {
        background: #b6bccd;
        border-radius: 50%;
        color: #343434;
      }

      &--third {
        background: #e37a5d;
        border-radius: 50%;
        color: #fff;
      }
    }
  }

  .user-stats {
    display: flex;
    align-items: center;
    gap: 10px;

    &__place {
      color: #fff;
      font-size: 66px;
      font-weight: 700;
      line-height: 80px;
    }

    &__score {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    &__name {
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
    }

    &__points {
      display: flex;
      gap: 5px;
      font-size: 25px;
      font-weight: 700;
      color: #ffcc8b;
      line-height: 30px;
    }
  }

  &__actions {
    position: fixed;
    bottom: 56px;
    left: 0;
    width: 100%;
    padding: 0 22px;
    z-index: 10;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  &__action {
    width: 159px !important;
    margin: 0 auto;
  }

  &__title {
    color: #343434;
    font-size: 22px;
    font-weight: 700;
  }
}
</style>
