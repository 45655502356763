<template>
  <div class="page-description">
    <div class="page-description__inner">
      <div class="page-description__main">
        <div class="page-description__image">
          <img :src="require('../assets/images/iwalk_new_logo.png')" alt="" />
        </div>
        <div class="page-description__about-project">
          <h2
            :style="{
              'font-size': '34px',
              'font-weight': '700',
              'line-height': '41.15px',
            }"
          >
            О проекте
          </h2>
          <p
            :style="{
              'font-size': '23px',
              'font-weight': '400',
              'line-height': '27.84px',
              color: '#343434',
            }"
          >
            Система работает очень просто. Пользователь выбирает маршрут, кликая по нужному названию
            на виртуальной карте, сервис предоставляет информацию о местных достопримечательностях
          </p>
        </div>
      </div>
      <div class="page-description__footer">
        <p v-if="!$store.state.token" class="page-description__auth-message">
          Для использования сервиса требуется авторизация
        </p>
        <div
          v-if="!$store.state.token"
          @click="sendNeedAuthMessage"
          class="page-description__action"
        >
          <p>Авторизоваться</p>
        </div>
        <div v-if="!!$store.state.token" @click="goToNextPage" class="page-description__action">
          <p>Далее</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DescriptionPage",

  methods: {
    goToNextPage() {
      this.$router.push({ name: "agree" });
    },
    sendNeedAuthMessage() {
      window.ReactNativeWebView.postMessage("iWalkNeedAuthMessage");
    },
  },
};
</script>

<style lang="scss">
.page-description {
  height: 100vh;

  &__header {
    margin-top: 30px;
  }

  &__inner {
    height: auto;
    min-height: 100%;
    padding: 56px 36px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: 34px;
    color: #fff;
    font-weight: 700;
  }

  &__footer {
    width: 100%;
    height: auto;
    min-height: 100%;
  }

  &__action {
    position: relative;
    // bottom: 12px;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    min-height: 65px;
    max-height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #56da39;
    margin-top: 30px;

    p {
      font-weight: 700;
      line-height: 32.68px;
      font-size: 22px;
      color: #fff;
    }
  }

  &__about-project {
    margin-top: 10px;
    > * {
      margin-bottom: 10px;
    }
  }

  &__auth-message {
    text-align: center;
    font-size: 19px;
    font-weight: 700;
    margin: 30px auto;
  }
}
</style>
